import React, { useEffect, useRef, useState } from 'react'
import Boy from './players/Boy'
import Sign from './objects/Sign'
import Girl from './players/Girl'
import {
  Html,
  PositionalAudio,
  SpriteAnimator,
  useTexture
} from '@react-three/drei'
import Block from './tiles/Block'
import Button from './objects/Button'
import Door from './objects/Door'
import {
  BODY_TYPES,
  BUTTONS,
  CATEGORY_GROUND,
  eventEmitter,
  usePhysics
} from './physics/Physics'
import Spring from './objects/Spring'
import { darkColor, GameState, lightColor, pressedButtons } from '../Game'
import Confetti from './objects/Confetti'
import type { PositionalAudio as PositionalAudioImpl } from 'three'
import { b2BodyType, b2PolygonShape } from '../../@box2d/core'

const Statics: React.FC<{ screenIsOn: boolean }> = ({ screenIsOn }) => {
  const grass1 = useTexture('./resources/sprites/world/Spring1_Dark.png')
  const grass2 = useTexture('./resources/sprites/world/Spring2_Dark.png')
  const flower1 = useTexture('./resources/sprites/world/Crocus_Purple.png')
  const flower2 = useTexture('./resources/sprites/world/Dandelion_Step2.png')
  const treeDark = useTexture('./resources/sprites/world/tree_Spring2_Dark.png')
  const treeLight = useTexture(
    './resources/sprites/world/tree_Spring2_Medium.png'
  )

  return (
    <>
      <object3D position={[0, -0.5, 0]}>
        <sprite scale={1} position={[0, 0, 0]} visible={false}>
          <spriteMaterial
            map={grass1}
            color={screenIsOn ? darkColor : lightColor}
          />
        </sprite>
      </object3D>
      <Sign x={-7.5} y={1} type={'heart'} screenIsOn={screenIsOn} />
      <sprite scale={0.7} position={[-5, 12.5, -0]}>
        <spriteMaterial
          map={grass1}
          color={screenIsOn ? darkColor : lightColor}
        />
      </sprite>
      <sprite scale={0.6} position={[1, 2.97, -0.2]}>
        <spriteMaterial
          map={grass2}
          color={screenIsOn ? darkColor : lightColor}
        />
      </sprite>
      <sprite scale={1} position={[-8, 6.69, -0.2]}>
        <spriteMaterial
          map={flower1}
          color={screenIsOn ? darkColor : lightColor}
        />
      </sprite>
      <sprite scale={1} position={[8, 10.65, -0.2]}>
        <spriteMaterial
          map={flower2}
          color={screenIsOn ? darkColor : lightColor}
        />
      </sprite>
      <sprite scale={3} position={[-6.7, 2, -0.9]}>
        <spriteMaterial
          map={treeDark}
          color={screenIsOn ? darkColor : lightColor}
        />
      </sprite>
      <sprite scale={3} position={[-10, 2, -0.9]}>
        <spriteMaterial
          map={treeDark}
          color={screenIsOn ? darkColor : lightColor}
        />
      </sprite>
      <sprite scale={3} position={[6, 2, 0.9]}>
        <spriteMaterial
          map={treeLight}
          color={screenIsOn ? darkColor : lightColor}
        />
      </sprite>
      <sprite scale={3} position={[8, 2, -0.9]}>
        <spriteMaterial
          map={treeDark}
          color={screenIsOn ? darkColor : lightColor}
        />
      </sprite>
      <object3D position={[-2.5, 14.5, -0.9]}>
        {screenIsOn && (
          <Html as={'div'}>
            <div
              className={'billboard'}
              style={{
                transform: 'translate3d(-50%, -50%, 0)',
                marginTop: '-10px'
              }}>
              <h2 className={screenIsOn ? 'visible ' : ''}>
                S<span className={'flicker-slower'}>av</span>e the date
              </h2>
              <h1 className={screenIsOn ? 'neon visible ' : 'neon'}>
                30. <span className={'flicker-fast'}>M</span>aj 2
                <span className={'flicker-slow'}>0</span>25
              </h1>
            </div>
          </Html>
        )}
        <SpriteAnimator
          loop={false}
          autoPlay={true}
          scale={[10, 10, 1]}
          textureImageURL={'./resources/sprites/world/screen.png'}
          textureDataURL={'./resources/sprites/world/screen.json'}
          playBackwards={!screenIsOn}
        />
        <Confetti screenIsOn={screenIsOn} />
      </object3D>
    </>
  )
}
const World: React.FC<{ state: GameState }> = ({ state }) => {
  const physics = usePhysics()
  const bgAudioRef = useRef<PositionalAudioImpl>(null!)
  const winSoundRef = useRef<PositionalAudioImpl>(null!)

  const [screenIsOn, setScreenIsOn] = useState(false)

  useEffect(() => {
    eventEmitter.on('button', () => {
      const isOn =
        !!pressedButtons[BUTTONS.ONE] && !!pressedButtons[BUTTONS.TWO]
      setScreenIsOn(isOn)
    })
  }, [])

  useEffect(() => {
    bgAudioRef.current.setVolume(1)
    winSoundRef.current.setVolume(0.5)

    if (screenIsOn) {
      if (bgAudioRef.current.isPlaying) {
        bgAudioRef.current.stop()
      }
      winSoundRef.current.play()
    } else {
      if (winSoundRef.current.isPlaying) {
        winSoundRef.current.stop()
      }
      if (!bgAudioRef.current.isPlaying) {
        bgAudioRef.current.play()
      }
    }
  }, [screenIsOn])

  useEffect(() => {
    if (state === GameState.running) {
      if (!bgAudioRef.current.isPlaying) {
        bgAudioRef.current.play()
      }
    }
  }, [state])

  useEffect(() => {
    const ground = physics.CreateBody({
      type: b2BodyType.b2_staticBody,
      position: { x: 0, y: 0 }
    })

    ground.CreateFixture({
      shape: new b2PolygonShape().SetAsBox(60 / 2 + 0.9, 0.5),
      userData: {
        type: BODY_TYPES.GROUND
      },
      filter: {
        categoryBits: CATEGORY_GROUND
      }
    })
  }, [])

  return (
    <object3D position={[0, -10, 0]}>
      <Statics screenIsOn={screenIsOn} />
      <Block segments={2} x={-0.5} y={2.5} screenIsOn={screenIsOn} />
      <Spring x={3} y={1.45} screenIsOn={screenIsOn} />

      <Block segments={1} x={-4} y={4.5} screenIsOn={screenIsOn} />
      <Block segments={1} x={-7.5} y={6} screenIsOn={screenIsOn} />
      <Block segments={4} x={-1.5} y={8} screenIsOn={screenIsOn} />
      <Door x={-2.8} y={9.57} screenIsOn={screenIsOn} />
      <Button x={0.5} y={8.66} id={BUTTONS.SPRING} screenIsOn={screenIsOn} />

      <Block segments={1} x={5.5} y={4.5} screenIsOn={screenIsOn} />
      <Block segments={2} x={9} y={6.5} screenIsOn={screenIsOn} />
      <Button x={10} y={7.18} id={BUTTONS.DOOR} screenIsOn={screenIsOn} />

      <Block segments={3} x={6} y={10} screenIsOn={screenIsOn} />
      <Block segments={10} x={-2} y={12} screenIsOn={screenIsOn} />
      <Button x={-7} y={12.68} id={BUTTONS.ONE} screenIsOn={screenIsOn} />
      <Button x={2} y={12.68} id={BUTTONS.TWO} screenIsOn={screenIsOn} />

      <Boy x={-5.5} y={2} />
      <Girl x={-9.5} y={1.5} />

      <PositionalAudio
        url={'./resources/audio/background.mp3'}
        distance={10}
        loop={true}
        isPlaying={false}
        ref={bgAudioRef}
      />

      <PositionalAudio
        url={'./resources/audio/winner.mp3'}
        distance={10}
        loop={false}
        isPlaying={false}
        ref={winSoundRef}
      />
    </object3D>
  )
}

export default World
