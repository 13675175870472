import React from 'react'
import { useTexture } from '@react-three/drei'
import { darkColor, lightColor } from '../../Game'

export interface SignProps {
  x: number
  y: number
  type: 'cross' | 'heart' | 'right' | 'left'
  screenIsOn: boolean
}

const Sign: React.FC<SignProps> = ({ type, x, y, screenIsOn }) => {
  const texture = useTexture(`./resources/sprites/world/signs/${type}.png`)

  return (
    <object3D position={[x, y, -0.1]}>
      <sprite>
        <spriteMaterial
          map={texture}
          color={screenIsOn ? darkColor : lightColor}
        />
      </sprite>
    </object3D>
  )
}

export default Sign
