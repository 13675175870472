import React, { useState } from 'react'
import { Canvas } from '@react-three/fiber'
import { KeyboardControls } from '@react-three/drei'
import Game, { GameState } from './game/Game'
import UI from './ui/UI'
import PhysicsDebug from './ui/PhysicsDebug'

export enum Controls {
  left = 'left',
  right = 'right',
  jump = 'jump',
  left1 = 'left1',
  right1 = 'right1',
  jump1 = 'jump1'
}

const App: React.FC = () => {
  const [state, setState] = useState(GameState.start)

  return (
    <div style={{ position: 'relative', width: '100vw', height: '100vh' }}>
      <KeyboardControls
        map={[
          { name: Controls.left, keys: ['ArrowLeft'] },
          { name: Controls.right, keys: ['ArrowRight'] },
          { name: Controls.jump, keys: ['ArrowUp'] },
          { name: Controls.left1, keys: ['KeyA'] },
          { name: Controls.right1, keys: ['KeyD'] },
          { name: Controls.jump1, keys: ['KeyW'] }
        ]}>
        <Canvas orthographic camera={{ zoom: 46, position: [0, 0, 50] }}>
          <Game state={state} />
        </Canvas>
      </KeyboardControls>
      <PhysicsDebug />
      <UI gameState={state} onClickStart={() => setState(GameState.running)} />
    </div>
  )
}

export default App
