import React, { useEffect, useRef, useState } from 'react'
import { PositionalAudio, useTexture } from '@react-three/drei'
import {
  BODY_TYPES,
  CATEGORY_GROUND,
  eventEmitter,
  usePhysics
} from '../physics/Physics'
import { b2BodyType, b2PolygonShape } from '../../../@box2d/core'
import { darkColor, lightColor, pressedButtons } from '../../Game'
import type { PositionalAudio as PositionalAudioImpl } from 'three/src/audio/PositionalAudio'

export interface ButtonProps {
  x: number
  y: number
  id: number
  screenIsOn: boolean
}

const Button: React.FC<ButtonProps> = ({ x, y, id, screenIsOn }) => {
  const physics = usePhysics()

  const platform = useTexture(
    './resources/sprites/world/interactables/button_platform.png'
  )
  const press = useTexture(
    './resources/sprites/world/interactables/button_press.png'
  )
  const soundRef = useRef<PositionalAudioImpl>(null!)
  const [isPressed, setIsPressed] = useState<boolean | undefined>(undefined)
  const [played, setPlayed] = useState(false)

  useEffect(() => {
    const handleButtonChange = () => {
      setIsPressed(pressedButtons[id])
    }

    eventEmitter.on('button', handleButtonChange)

    soundRef.current.setVolume(0.5)
    const platform = physics.CreateBody({
      type: b2BodyType.b2_staticBody,
      position: { x, y: y - 0.3 }
    })
    platform.CreateFixture({
      shape: new b2PolygonShape().SetAsBox(0.4, 0.1),
      isSensor: false,
      userData: {
        type: BODY_TYPES.BUTTON,
        id
      },
      filter: {
        categoryBits: CATEGORY_GROUND
      }
    })
    platform.CreateFixture({
      shape: new b2PolygonShape().SetAsBox(0.5, 0.05),
      userData: {
        type: BODY_TYPES.BUTTON_FRAME,
        id
      },
      filter: {
        categoryBits: CATEGORY_GROUND
      }
    })
  }, [])

  useEffect(() => {
    if (isPressed) {
      if (!soundRef.current.isPlaying && !played) {
        soundRef.current.play()
        setPlayed(true)
      }
    } else if (isPressed === false) {
      setPlayed(false)
    }
  }, [isPressed])

  return (
    <object3D position={[x, y, 0]}>
      <object3D position={[0, isPressed ? -0.07 : 0, 0]}>
        <sprite position={[0, -0.2, 0]} scale={[1.4, 1, 1]}>
          <spriteMaterial
            map={press}
            color={screenIsOn ? darkColor : lightColor}
          />
        </sprite>
      </object3D>
      <sprite scale={[1.5, 1, 1]}>
        <spriteMaterial
          map={platform}
          color={screenIsOn ? darkColor : lightColor}
        />
      </sprite>
      <PositionalAudio
        url={'./resources/audio/button.mp3'}
        loop={false}
        ref={soundRef}
        distance={100}
        playbackRate={1}
      />
    </object3D>
  )
}

export default Button
