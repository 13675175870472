import React from 'react'
import StartScreen from './StartScreen'
import { GameState } from '../game/Game'

export interface UIProps {
  gameState: GameState
  onClickStart: () => void
}

const UI: React.FC<UIProps> = ({ gameState, onClickStart }) => {
  return (
    <>
      <StartScreen
        onClickStart={onClickStart}
        isVisible={gameState === GameState.start}
      />
    </>
  )
}

export default UI
