import React, { useEffect, useRef, useState } from 'react'
import {
  PositionalAudio,
  SpriteAnimator,
  useKeyboardControls,
  useTexture
} from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import {
  BODY_TYPES,
  BUTTONS,
  CATEGORY_GROUND,
  CATEGORY_PLATFORM,
  CATEGORY_PLAYER_1,
  CATEGORY_PLAYER_2,
  eventEmitter,
  PLAYER_TYPES,
  usePhysics
} from '../physics/Physics'
import { Object3D } from 'three'
import { Controls } from '../../../App'
import { pressedButtons, useGame } from '../../Game'
import { boyStates, center, jumpImpulse, PlayerProps } from './Boy'
import {
  b2Body,
  b2BodyType,
  b2PolygonShape,
  b2Vec2
} from '../../../@box2d/core'
import toggleFacingDirection from './helpers/toggleFacingDirection'
import move from './helpers/move'
import type { PositionalAudio as PositionalAudioImpl } from 'three/src/audio/PositionalAudio'

const PlayerState = {
  idle: 'idle',
  run: 'run',
  jump: 'jump',
  fall: 'fall'
}

export const girlStates = {
  canJump: false,
  inDoor: false,
  touchLeft: false,
  touchRight: false,
  onSpring: false,
  touchingDoor: false
}

const Girl: React.FC<PlayerProps> = ({ x, y }) => {
  const [, getControlState] = useKeyboardControls<Controls>()
  const physics = usePhysics()
  const { setGirl } = useGame()
  const controls = useTexture('./resources/sprites/controls_girl.png')

  const objectRef = useRef<Object3D>(null!)
  const bodyRef = useRef<b2Body>()
  const jumpCooldownRef = useRef(0)
  const footstepRef = useRef<PositionalAudioImpl>(null!)
  const jumpSoundRef = useRef<PositionalAudioImpl>(null!)
  const velocityRef = useRef<b2Vec2>(new b2Vec2())
  const impulseRef = useRef<b2Vec2>(new b2Vec2())

  const [playerState, setPlayerState] = useState(PlayerState.idle)
  const [facingRight, setFacingRight] = useState(true)
  const [inDoor, setInDoor] = useState(false)
  const [showControls, setShowControls] = useState(true)

  useEffect(() => {
    footstepRef.current.setVolume(0.3)
    jumpSoundRef.current.setVolume(0.2)
    setGirl(objectRef.current)
    bodyRef.current = physics.CreateBody({
      type: b2BodyType.b2_dynamicBody,
      fixedRotation: true,
      position: { x, y },
      allowSleep: false
    })

    bodyRef.current?.CreateFixture({
      shape: new b2PolygonShape().SetAsBox(0.3, 0.6),
      density: 10,
      friction: 20,
      userData: {
        player: PLAYER_TYPES.GIRL,
        type: BODY_TYPES.PLAYER
      },
      filter: {
        categoryBits: CATEGORY_PLAYER_2,
        maskBits: CATEGORY_GROUND | CATEGORY_PLATFORM
      }
    })

    bodyRef.current?.CreateFixture({
      shape: new b2PolygonShape().SetAsBox(0.3, 0.1, { x: 0, y: -0.6 }, 0),
      isSensor: true,
      userData: {
        player: PLAYER_TYPES.GIRL,
        type: BODY_TYPES.PLAYER_FOOT
      }
    })

    bodyRef.current?.CreateFixture({
      shape: new b2PolygonShape().SetAsBox(0.1, 0.58, { x: 0.2, y: 0 }),
      isSensor: true,
      userData: {
        player: PLAYER_TYPES.GIRL,
        type: BODY_TYPES.PLAYER_WALL_R
      },
      filter: {
        categoryBits: CATEGORY_PLAYER_1,
        maskBits: CATEGORY_GROUND | CATEGORY_PLATFORM
      }
    })

    bodyRef.current?.CreateFixture({
      shape: new b2PolygonShape().SetAsBox(0.1, 0.58, { x: -0.2, y: 0 }),
      isSensor: true,
      userData: {
        player: PLAYER_TYPES.GIRL,
        type: BODY_TYPES.PLAYER_WALL_L
      },
      filter: {
        categoryBits: CATEGORY_PLAYER_1,
        maskBits: CATEGORY_GROUND | CATEGORY_PLATFORM
      }
    })
  }, [])

  useEffect(() => {
    eventEmitter.on('button', () => {
      if (pressedButtons[BUTTONS.SPRING] && girlStates.onSpring) {
        if (bodyRef.current) {
          bodyRef.current?.ApplyLinearImpulse({ x: 0, y: 150 }, center)
        }
      }
      if (pressedButtons[BUTTONS.DOOR] && girlStates.touchingDoor) {
        girlStates.touchRight = false
        girlStates.touchLeft = false
      }
    })
  }, [])

  useEffect(() => {
    if (playerState === PlayerState.run) {
      if (!footstepRef.current.isPlaying) {
        footstepRef.current.setLoop(true)
        footstepRef.current.play()
      }
    } else {
      footstepRef.current.setLoop(false)
    }
  }, [playerState])

  useFrame((_, delta) => {
    const { left1: left, right1: right, jump1: jump } = getControlState()

    const velocity = bodyRef.current?.GetLinearVelocity()
    if (velocity) {
      velocityRef.current.x = velocity.x
      velocityRef.current.y = velocity.y
    }

    jumpCooldownRef.current += delta

    if (showControls && (left || right || jump)) {
      setShowControls(false)
    }

    if (left && girlStates.touchRight) {
      girlStates.touchRight = false
    }

    if (right && girlStates.touchLeft) {
      girlStates.touchLeft = false
    }

    if (velocity) {
      move(
        left,
        right,
        velocity.x,
        girlStates.touchRight,
        girlStates.touchLeft,
        impulseRef.current,
        bodyRef.current
      )
    }

    if (!girlStates.canJump) {
      if (!left && !right) {
        velocityRef.current.x *= Math.pow(0.96, delta * 60)
        bodyRef.current?.SetLinearVelocity(velocityRef.current)
      }
      if ((velocity?.y || 0) < 0 && playerState !== PlayerState.fall) {
        setPlayerState(PlayerState.fall)
      } else if ((velocity?.y || 0) >= 0 && playerState !== PlayerState.jump) {
        setPlayerState(PlayerState.jump)
      }
    }

    if (
      !left &&
      !right &&
      girlStates.canJump &&
      jumpCooldownRef.current > 0.5 &&
      playerState !== PlayerState.idle
    ) {
      setPlayerState(PlayerState.idle)
    } else if (
      (left || right) &&
      girlStates.canJump &&
      jumpCooldownRef.current > 0.5 &&
      playerState !== PlayerState.run
    ) {
      setPlayerState(PlayerState.run)
    }

    if (
      jump &&
      girlStates.canJump &&
      jumpCooldownRef.current > 0.5 &&
      !((velocity?.y || 0) > 0)
    ) {
      jumpCooldownRef.current = 0.25
      setPlayerState(PlayerState.jump)
      if (jumpSoundRef.current.isPlaying) {
        jumpSoundRef.current.stop()
      }
      jumpSoundRef.current.play()
      bodyRef.current?.ApplyLinearImpulse({ x: 0, y: jumpImpulse }, center)
    }

    if (bodyRef.current && objectRef.current) {
      const { x, y } = bodyRef.current.GetPosition()
      objectRef.current.position.set(x, y, objectRef.current.position.z)
      toggleFacingDirection(left, right, facingRight, setFacingRight)
    }

    if (inDoor !== girlStates.inDoor) {
      setInDoor(girlStates.inDoor)
    }
  })

  return (
    <object3D
      name={'players'}
      ref={objectRef}
      position={[0, 0, girlStates.inDoor ? -0.4 : 0.5]}>
      {showControls && (
        <sprite position={[0, 1.5, 0]} scale={[86 / 30, 58 / 30, 1]}>
          <spriteMaterial map={controls} />
        </sprite>
      )}

      <SpriteAnimator
        autoPlay={true}
        loop={true}
        fps={16}
        flipX={facingRight}
        scale={1.8}
        textureImageURL={'./resources/sprites/woman/woman.png'}
        textureDataURL={'./resources/sprites/woman/woman.json'}
        animationNames={['idle', 'fall', 'jump', 'run']}
        frameName={playerState}
        position={[facingRight ? -0.1 : 0.1, -0.1, 0]}
      />
      <PositionalAudio
        url={'./resources/audio/footstep.mp3'}
        loop={true}
        ref={footstepRef}
        distance={100}
        playbackRate={3}
      />
      <PositionalAudio
        url={'./resources/audio/jump_female.mp3'}
        loop={false}
        ref={jumpSoundRef}
        distance={100}
        playbackRate={1.5}
      />
    </object3D>
  )
}

export default Girl
