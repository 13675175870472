const toggleFacingDirection = (
  left: boolean,
  right: boolean,
  facingRight: boolean,
  setFacingRight: (facingRight: boolean) => void
) => {
  if (left && facingRight) {
    setFacingRight(false)
  } else if (right && !facingRight) {
    setFacingRight(true)
  }
}

export default toggleFacingDirection
