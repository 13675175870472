import React, { useEffect, useRef, useState } from 'react'
import { PositionalAudio, SpriteAnimator } from '@react-three/drei'
import {
  BODY_TYPES,
  BUTTONS,
  CATEGORY_PLATFORM,
  eventEmitter,
  usePhysics
} from '../physics/Physics'
import {
  b2Body,
  b2BodyType,
  b2Fixture,
  b2PolygonShape
} from '../../../@box2d/core'
import { pressedButtons } from '../../Game'
import type { PositionalAudio as PositionalAudioImpl } from 'three/src/audio/PositionalAudio'

export interface DoorProps {
  x: number
  y: number
  screenIsOn: boolean
}

const Door: React.FC<DoorProps> = ({ x, y, screenIsOn }) => {
  const physics = usePhysics()

  const [isOpen, setIsOpen] = useState<boolean | undefined>(undefined)
  const bodyRef = useRef<b2Body>()
  const fixtureRef = useRef<b2Fixture>()
  const doorPositionFixtureRef = useRef<b2Fixture>()
  const doorSoundRef = useRef<PositionalAudioImpl>(null!)
  const doorReverseSoundRef = useRef<PositionalAudioImpl>(null!)
  const [light, setLight] = useState(true)

  useEffect(() => {
    eventEmitter.on('button', () => {
      if (pressedButtons[BUTTONS.DOOR] !== undefined) {
        setIsOpen(pressedButtons[BUTTONS.DOOR])
        fixtureRef.current?.SetSensor(!!pressedButtons[BUTTONS.DOOR])
      }
    })

    doorSoundRef.current.setVolume(0.1)
    doorReverseSoundRef.current.setVolume(0.1)
    bodyRef.current = physics.CreateBody({
      type: b2BodyType.b2_staticBody,
      position: { x, y }
    })
    fixtureRef.current = bodyRef.current.CreateFixture({
      shape: new b2PolygonShape().SetAsBox(0.1, 1.8, { x: 0, y: 0.4 }),
      userData: {
        type: BODY_TYPES.DOOR_FRAME
      },
      filter: {
        categoryBits: CATEGORY_PLATFORM
      }
    })

    bodyRef.current.CreateFixture({
      shape: new b2PolygonShape().SetAsBox(0.4, 0.4, { x: 0.35, y: 1.4 }),
      filter: {
        categoryBits: CATEGORY_PLATFORM
      }
    })

    doorPositionFixtureRef.current = bodyRef.current?.CreateFixture({
      shape: new b2PolygonShape().SetAsBox(0.5, 2, { x: 0.6, y: 0.65 }),
      isSensor: true,
      userData: {
        type: BODY_TYPES.DOOR
      },
      filter: {
        categoryBits: CATEGORY_PLATFORM
      }
    })
  }, [])

  useEffect(() => {
    if (isOpen) {
      doorSoundRef.current.play()
    } else if (isOpen === false) {
      doorReverseSoundRef.current.play()
    }
  }, [isOpen])

  useEffect(() => {
    setLight(!screenIsOn)
  }, [screenIsOn])

  return (
    <object3D position={[x, y, 0]}>
      <SpriteAnimator
        autoPlay={true}
        loop={false}
        scale={3}
        textureImageURL={'./resources/sprites/world/interactables/door.png'}
        textureDataURL={'./resources/sprites/world/interactables/door.json'}
        fps={30}
        flipX={true}
        playBackwards={!isOpen}
        visible={light}
      />
      <SpriteAnimator
        autoPlay={true}
        loop={false}
        scale={3}
        textureImageURL={
          './resources/sprites/world/interactables/door_dark.png'
        }
        textureDataURL={'./resources/sprites/world/interactables/door.json'}
        fps={30}
        flipX={true}
        playBackwards={!isOpen}
        visible={!light}
      />
      <PositionalAudio
        url={'./resources/audio/door.mp3'}
        loop={false}
        ref={doorSoundRef}
        distance={100}
        playbackRate={2}
      />
      <PositionalAudio
        url={'./resources/audio/door_reverse.mp3'}
        loop={false}
        ref={doorReverseSoundRef}
        distance={100}
        playbackRate={2}
      />
    </object3D>
  )
}

export default Door
