import React, { useEffect, useRef, useState } from 'react'
import { PositionalAudio, SpriteAnimator } from '@react-three/drei'
import {
  BODY_TYPES,
  CATEGORY_PLATFORM,
  eventEmitter,
  usePhysics
} from '../physics/Physics'
import { b2Body, b2BodyType, b2PolygonShape } from '../../../@box2d/core'
import type { PositionalAudio as PositionalAudioImpl } from 'three/src/audio/PositionalAudio'

export interface SpringProps {
  x: number
  y: number
  screenIsOn: boolean
}

const Spring: React.FC<SpringProps> = ({ x, y, screenIsOn }) => {
  const physics = usePhysics()

  const bodyRef = useRef<b2Body>()
  const soundRef = useRef<PositionalAudioImpl>(null!)
  const [isUp, setIsUp] = useState(false)

  useEffect(() => {
    eventEmitter.on('spring', () => {
      if (soundRef.current.isPlaying) {
        soundRef.current.stop()
      }
      soundRef.current.play()
      setIsUp(true)
      setTimeout(() => {
        setIsUp(false)
      }, 100)
    })

    soundRef.current.setVolume(0.5)
    bodyRef.current = physics.CreateBody({
      type: b2BodyType.b2_staticBody,
      position: { x, y }
    })

    bodyRef.current.CreateFixture({
      shape: new b2PolygonShape().SetAsBox(0.8, 0.4, { x: 0, y: -0.55 }),
      userData: {
        type: BODY_TYPES.SPRING
      },
      filter: {
        categoryBits: CATEGORY_PLATFORM
      }
    })
  }, [])

  return (
    <object3D position={[x, y, 0]}>
      <SpriteAnimator
        autoPlay={true}
        loop={false}
        scale={2}
        textureImageURL={'./resources/sprites/world/interactables/spring.png'}
        textureDataURL={'./resources/sprites/world/interactables/spring.json'}
        playBackwards={!isUp}
        fps={isUp ? 80 : 20}
        visible={!screenIsOn}
      />
      <SpriteAnimator
        autoPlay={true}
        loop={false}
        scale={2}
        textureImageURL={
          './resources/sprites/world/interactables/spring_dark.png'
        }
        textureDataURL={'./resources/sprites/world/interactables/spring.json'}
        playBackwards={!isUp}
        fps={isUp ? 80 : 20}
        visible={screenIsOn}
      />
      <PositionalAudio
        url={'./resources/audio/spring.mp3'}
        loop={false}
        ref={soundRef}
        distance={100}
        playbackRate={1.2}
      />
    </object3D>
  )
}

export default Spring
