import React, { useEffect, useRef } from 'react'
import System, {
  Body,
  Color,
  ease,
  Emitter,
  Gravity,
  Life,
  Mass,
  MeshRenderer,
  PointZone,
  Position,
  RadialVelocity,
  RandomDrift,
  Rate,
  Rotate,
  Scale,
  Span,
  SpriteRenderer,
  Vector3D,
  VectorVelocity
} from 'three-nebula'
import { useFrame, useThree } from '@react-three/fiber'
import * as THREE from 'three'
import {
  DoubleSide,
  Mesh,
  MeshBasicMaterial,
  PlaneGeometry,
  Sprite,
  SpriteMaterial
} from 'three'
import { useTexture } from '@react-three/drei'

const Confetti: React.FC<{ screenIsOn: boolean }> = ({ screenIsOn }) => {
  const { scene } = useThree()
  const jager = useTexture('./resources/sprites/jager.png')
  const dot = useTexture('./resources/sprites/dot.png')

  const create = () => {
    const dotMaterial = new SpriteMaterial({
      map: dot,
      color: 0xffffff,
      fog: false
    })
    return new Sprite(dotMaterial)
  }

  const systemRef = useRef<System>()
  const systemRef2 = useRef<System>()
  const systemRef3 = useRef<System>()

  const emitterRef = useRef<Emitter>()
  const emitterRef2 = useRef<Emitter>()
  const emitterRef3 = useRef<Emitter>()

  const rendererRef = useRef<SpriteRenderer>()
  const rendererRef2 = useRef<SpriteRenderer>()
  const rendererRef3 = useRef<MeshRenderer>()

  useEffect(() => {
    systemRef.current = new System()
    systemRef2.current = new System()
    systemRef3.current = new System()

    emitterRef.current = new Emitter()
    emitterRef2.current = new Emitter()
    emitterRef3.current = new Emitter()

    // @ts-ignore
    rendererRef.current = new SpriteRenderer(scene, THREE)
    // @ts-ignore
    rendererRef2.current = new SpriteRenderer(scene, THREE)
    // @ts-ignore
    rendererRef3.current = new MeshRenderer(scene, THREE)

    // SYSTEM 1
    emitterRef.current
      .setRate(new Rate(new Span(5, 6), new Span(0.02, 0.05)))
      .setInitializers([
        new Body(create()),
        new Mass(1),
        new Life(7, 10),
        new VectorVelocity(new Vector3D(4, 4, 0), 0)
      ])
      .addBehaviours([
        new RandomDrift(1, 0.5, 0, 0.05),
        new Scale(0.125, 0.17),
        new Gravity(0.025),
        new Color(
          // @ts-ignore
          new THREE.Color(0xff0026),
          ['#ffff00', '#ffff11'],
          Infinity,
          ease.easeOutSine
        )
      ])
      .setPosition({ x: 0.5, y: 7, z: 10 })
      .emit()

    systemRef.current
      .addEmitter(emitterRef.current)
      .addRenderer(rendererRef.current)

    // SYSTEM 2
    emitterRef2.current
      .setRate(new Rate(new Span(5, 6), new Span(0.02, 0.05)))
      .setInitializers([
        new Body(create()),
        new Mass(1),
        new Life(7, 10),
        new VectorVelocity(new Vector3D(-4, 4, 0), 0)
      ])
      .addBehaviours([
        new RandomDrift(1, 0.5, 0, 0.05),
        new Scale(0.125, 0.17),
        new Gravity(0.025),
        new Color(
          // @ts-ignore
          new THREE.Color(0xff0026),
          ['#ffff00', '#ffff11'],
          Infinity,
          ease.easeOutSine
        )
      ])
      .setPosition({ x: -5.5, y: 7, z: 10 })

    systemRef2.current
      .addEmitter(emitterRef2.current)
      .addRenderer(rendererRef2.current)

    // SYSTEM 3
    emitterRef3.current
      .setRate(new Rate(1, new Span(1, 2)))
      .setInitializers([
        new Position(new PointZone(0, 0)),
        new Mass(1),
        new Life(5, 10),
        new Body(
          new Mesh(
            new PlaneGeometry(1, 1),
            new MeshBasicMaterial({
              map: jager,
              transparent: true,
              color: 0xcccccc,
              side: DoubleSide
            })
          )
        ),
        new RadialVelocity(10, new Vector3D(0, 1, 0), 80)
      ])
      .addBehaviours([new Rotate(0, 0, 1), new Scale(1, 1), new Gravity(0.08)])
      .setPosition({ x: -1, y: 7.5, z: 5 })

    systemRef3.current
      .addEmitter(emitterRef3.current)
      .addRenderer(rendererRef3.current)
  }, [])

  useEffect(() => {
    if (screenIsOn) {
      emitterRef.current?.emit(30)
      emitterRef2.current?.emit(30)
      emitterRef3.current?.emit(30)
    } else {
      emitterRef.current?.emit(0)
      emitterRef2.current?.emit(0)
      emitterRef3.current?.emit(0)
    }
  }, [screenIsOn])

  useFrame((_, delta) => {
    systemRef.current?.update(delta)
    systemRef2.current?.update(delta)
    systemRef3.current?.update(delta)
  })

  return <></>
}

export default Confetti
