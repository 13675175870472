import React, { createContext, useContext, useEffect, useState } from 'react'
import World from './world/World'
import { BUTTONS, eventEmitter, Physics } from './world/physics/Physics'
import { Color, Object3D, RepeatWrapping } from 'three'
import '../ui/styles.css'
import { Bounds, useTexture } from '@react-three/drei'

export enum GameState {
  running,
  paused,
  start
}

export interface GameDefinition {
  boy?: Object3D
  girl?: Object3D
  setBoy: (boy: Object3D) => void
  setGirl: (girl: Object3D) => void
}

const GameContext = createContext<GameDefinition>(null!)

export const darkColor = new Color(0x777777)
export const lightColor = new Color(0xffffff)

export interface GameProps {
  state: GameState
}

export const pressedButtons: {
  [key: number]: boolean | undefined
} = {}

const Statics: React.FC = () => {
  const background = useTexture(
    './resources/sprites/background/Landscape1_Day.png'
  ).clone()
  const backgroundDark = useTexture(
    './resources/sprites/background/Landscape1_StarryNight.png'
  ).clone()
  background.wrapS = RepeatWrapping
  background.repeat.set(2, 1)

  backgroundDark.wrapS = RepeatWrapping
  backgroundDark.repeat.set(2, 1)
  const [screenIsOn, setScreenIsOn] = useState(false)
  useEffect(() => {
    eventEmitter.on('button', () => {
      const isOn = pressedButtons[BUTTONS.ONE] && pressedButtons[BUTTONS.TWO]
      setScreenIsOn(!!isOn)
    })
  }, [])
  const ground = useTexture('./resources/sprites/world/top.png').clone()
  ground.wrapS = RepeatWrapping
  ground.repeat.set(80, 1)

  const dirt = useTexture('./resources/sprites/world/dirt.png').clone()
  dirt.wrapS = RepeatWrapping
  dirt.wrapT = RepeatWrapping
  dirt.repeat.set(80, 20)

  return (
    <>
      <sprite scale={[2048 / 25, 1280 / 50, 1]} position={[0, 3, -5]}>
        <spriteMaterial map={screenIsOn ? backgroundDark : background} />
      </sprite>
      <sprite scale={[80, 1, 1]} position={[0, -10, 1]}>
        <spriteMaterial
          map={ground}
          color={screenIsOn ? darkColor : lightColor}
        />
      </sprite>
      <sprite scale={[80, 20, 1]} position={[0, -20, 1]}>
        <spriteMaterial
          map={dirt}
          color={screenIsOn ? darkColor : lightColor}
        />
      </sprite>
    </>
  )
}

const Game: React.FC<GameProps> = ({ state }) => {
  const [boy, setBoy] = useState<Object3D | undefined>()
  const [girl, setGirl] = useState<Object3D | undefined>()

  return (
    <object3D name={'game'}>
      <GameContext.Provider
        value={{
          boy,
          girl,
          setBoy,
          setGirl
        }}>
        <Statics />
        <Physics>
          <Bounds fit clip observe margin={1} maxDuration={0.2}>
            <World state={state} />
          </Bounds>
        </Physics>
      </GameContext.Provider>
    </object3D>
  )
}

export const useGame = (): GameDefinition => useContext(GameContext)

export default Game
