import { b2Body, b2Vec2, XY } from '../../../../@box2d/core'
import { center } from '../Boy'

export const moveSped = 5

const move = (
  left: boolean,
  right: boolean,
  velocity: number,
  touchingRightWall: boolean,
  touchingLeftWall: boolean,
  impulse: b2Vec2,
  body?: b2Body
) => {
  const mass = body?.GetMass() || 1
  if (right && !touchingRightWall) {
    const velChange = moveSped - velocity
    impulse.x = mass * velChange
    body?.ApplyLinearImpulse(impulse, center)
  }
  if (left && !touchingLeftWall) {
    const velChange = -moveSped - velocity
    impulse.x = mass * velChange
    body?.ApplyLinearImpulse(impulse, center)
  }
}

export default move
