import React, { useEffect, useState } from 'react'
import { is } from '@react-three/fiber/dist/declarations/src/core/utils'

export interface StartScreenProps {
  onClickStart: () => void
  isVisible: boolean
}

const StartScreen: React.FC<StartScreenProps> = ({
  isVisible,
  onClickStart
}) => {
  const [hide, setHide] = useState(false)
  const [load, setLoad] = useState(false)
  const [done, setDone] = useState(false)

  useEffect(() => {
    setLoad(true)
    setTimeout(() => {
      setDone(true)
    }, 3000)
  }, [])
  useEffect(() => {
    if (!isVisible) {
      setTimeout(() => {
        setHide(true)
      }, 300)
    }
  }, [isVisible])

  if (hide) return <></>

  return (
    <div
      className={`fixed w-full h-full top-0 start-screen z-[99999999999999999] transition-opacity ease-out ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
      <div className="flex relative z-10 h-full justify-center items-center">
        <div className="flex bigscreen flex-col items-center text-center w-full sm:w-[640px] h-[480px] box-border p-9 sm:p-20">
          <p
            className={
              'text-xl sm:text-4xl leading-8 sm:leading-[45px] mb-4 xs:mt-4 sm:mb-12 text py-4 mt-4'
            }>
            Steffen og Manja holder bryllup!
          </p>
          <div className={'w-full px-4'}>
            <div
              className="track w-full h-1.5 mb-4 sm:mb-12 transition-opacity"
              style={{ opacity: done ? 0 : 1 }}>
              <div
                className="slider h-1.5"
                style={{ width: load ? '100%' : 0 }}></div>
            </div>
          </div>
          <button
            className={
              'text-white uppercase mt-1 bg-green-700 hover:bg-green-800 outline font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-8 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 shadow-2xl'
            }
            disabled={!done}
            onClick={onClickStart}
            style={{
              opacity: done ? '1' : '0.5'
            }}>
            Men hvornår?
          </button>
        </div>
      </div>
      <div className="fixed top-0 w-full h-full bg-gray-950" />
    </div>
  )
}

export default StartScreen
