import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { useFrame } from '@react-three/fiber'
import { pressedButtons } from '../../Game'
import {
  b2AABB,
  b2Body,
  b2Contact,
  b2ContactListener,
  b2Fixture,
  b2World,
  DrawAABBs,
  DrawCenterOfMasses,
  DrawJoints,
  DrawPairs,
  DrawShapes
} from '../../../@box2d/core'
import { DebugDraw } from '../../../@box2d/debug-draw'
import { boyStates } from '../players/Boy'
import { girlStates } from '../players/Girl'
import { EventEmitter } from 'events'

const PhysicsContext = createContext<b2World>(null!)

const fixedTimeStep = 1 / 60

const drawDebug = (draw: DebugDraw, world: b2World, aabb?: b2AABB) => {
  draw.Prepare(0, 0, 30, true)

  DrawShapes(draw, world, aabb)
  DrawJoints(draw, world)
  DrawAABBs(draw, world, aabb)
  DrawPairs(draw, world)
  DrawCenterOfMasses(draw, world)

  draw.Finish()
}

export const CATEGORY_PLAYER_1 = 0x0001
export const CATEGORY_PLAYER_2 = 0x0002
export const CATEGORY_GROUND = 0x0004
export const CATEGORY_PLATFORM = 0x0008

export const BODY_TYPES = {
  PLAYER: 0,
  PLAYER_FOOT: 1,
  GROUND: 2,
  PLATFORM: 3,
  BUTTON: 4,
  DOOR: 5,
  SPRING: 6,
  PLAYER_WALL_R: 7,
  PLAYER_WALL_L: 8,
  BUTTON_FRAME: 9,
  DOOR_FRAME: 10
}

export const PLAYER_TYPES = {
  BOY: 0,
  GIRL: 1
}

export const BUTTONS = {
  DOOR: 1,
  SPRING: 2,
  ONE: 3,
  TWO: 4
}

const canJumpOnType = (type: number, fixture: b2Fixture) => {
  return (
    !fixture.IsSensor() &&
    (type === BODY_TYPES.PLATFORM ||
      type === BODY_TYPES.GROUND ||
      type === BODY_TYPES.BUTTON ||
      type === BODY_TYPES.BUTTON_FRAME ||
      type === BODY_TYPES.DOOR ||
      type === BODY_TYPES.SPRING)
  )
}

export interface PhysicsProps extends PropsWithChildren {}

const isPlayer = (type: number) => type === BODY_TYPES.PLAYER

export const eventEmitter = new EventEmitter()
eventEmitter.setMaxListeners(20)

export const Physics: React.FC<PhysicsProps> = ({ children }) => {
  const [physicsWorld, setPhysicsWorld] = useState<b2World>(null!)
  const [debugDraw, setDebugDraw] = useState<DebugDraw>(null!)

  const boyFootContactsRef = useRef<number>(0)
  const girlFootContactsRef = useRef<number>(0)

  const handlePlayerCollidedWithPlatform = (
    playerBody: b2Body,
    platformBody: b2Body,
    contact: b2Contact
  ) => {
    const playerIsAbove =
      playerBody.GetPosition().y > platformBody.GetPosition().y
    const playerMovingDown = playerBody.GetLinearVelocity().y < 0
    contact.SetEnabled(playerIsAbove && playerMovingDown)
  }

  const handleFootEnterContact = (playerType: number) => {
    if (playerType === PLAYER_TYPES.BOY) {
      boyFootContactsRef.current += 1
      if (boyFootContactsRef.current > 0) {
        boyStates.canJump = true
      }
    } else if (playerType === PLAYER_TYPES.GIRL) {
      girlFootContactsRef.current += 1
      if (girlFootContactsRef.current > 0) {
        girlStates.canJump = true
      }
    }
  }

  const handleFootLeaveContact = (playerType: number) => {
    if (playerType === PLAYER_TYPES.BOY) {
      boyFootContactsRef.current -= 1
      if (boyFootContactsRef.current < 1) {
        boyStates.canJump = false
      }
    } else if (playerType === PLAYER_TYPES.GIRL) {
      girlFootContactsRef.current -= 1
      if (girlFootContactsRef.current < 1) {
        girlStates.canJump = false
      }
    }
  }

  const handleEnterButton = (buttonId: number) => {
    pressedButtons[buttonId] = true
    if (buttonId === BUTTONS.SPRING) {
      eventEmitter.emit('spring')
    }
    eventEmitter.emit('button')
  }

  const handleLeaveButton = (buttonId: number) => {
    pressedButtons[buttonId] = false
    eventEmitter.emit('button')
  }

  const handleDoorEnterContact = (playerType: number) => {
    if (playerType === PLAYER_TYPES.BOY) {
      boyStates.inDoor = true
    } else if (playerType === PLAYER_TYPES.GIRL) {
      girlStates.inDoor = true
    }
  }

  const handleDoorLeaveContact = (playerType: number) => {
    if (playerType === PLAYER_TYPES.BOY) {
      boyStates.inDoor = false
    } else if (playerType === PLAYER_TYPES.GIRL) {
      girlStates.inDoor = false
    }
  }

  const handleSpringEnterContact = (playerType: number) => {
    if (playerType === PLAYER_TYPES.BOY) {
      boyStates.onSpring = true
    } else if (playerType === PLAYER_TYPES.GIRL) {
      girlStates.onSpring = true
    }
  }

  const handleSpringLeaveContact = (playerType: number) => {
    if (playerType === PLAYER_TYPES.BOY) {
      boyStates.onSpring = false
    } else if (playerType === PLAYER_TYPES.GIRL) {
      girlStates.onSpring = false
    }
  }

  const handlePlayerTouchRightWall = (
    playerType: number,
    fixture: b2Fixture,
    rightWall: boolean
  ) => {
    if (fixture.IsSensor()) return
    const isDoorFrame = fixture.GetUserData().type === BODY_TYPES.DOOR_FRAME
    if (playerType === PLAYER_TYPES.BOY) {
      if (isDoorFrame) {
        boyStates.touchingDoor = rightWall
      }
      boyStates.touchRight = rightWall
    } else if (playerType === PLAYER_TYPES.GIRL) {
      if (isDoorFrame) {
        girlStates.touchingDoor = rightWall
      }
      girlStates.touchRight = rightWall
    }
  }

  const handlePlayerTouchLeftWall = (
    playerType: number,
    fixture: b2Fixture,
    leftWall: boolean
  ) => {
    if (fixture.IsSensor()) return
    const isDoorFrame = fixture.GetUserData().type === BODY_TYPES.DOOR_FRAME
    if (playerType === PLAYER_TYPES.BOY) {
      if (isDoorFrame) {
        boyStates.touchingDoor = leftWall
      }
      boyStates.touchLeft = leftWall
    } else if (playerType === PLAYER_TYPES.GIRL) {
      if (isDoorFrame) {
        girlStates.touchingDoor = leftWall
      }
      girlStates.touchLeft = leftWall
    }
  }

  useEffect(() => {
    const debugCanvas = document.getElementById(
      'physics-canvas'
    ) as HTMLCanvasElement
    if (debugCanvas) {
      const context = debugCanvas.getContext('2d')
      if (context) {
        setDebugDraw(new DebugDraw(context))
      }
    }

    const listener = new b2ContactListener()

    listener.BeginContact = (contact) => {
      const fixA = contact.GetFixtureA()
      const fixB = contact.GetFixtureB()
      const userA = fixA.GetUserData()
      const userB = fixB.GetUserData()
      if (userA && userB) {
        const typeA = userA.type
        const playerA = userA.player
        const bodyA = fixA.GetBody()
        const typeB = userB.type
        const playerB = userB.player
        const bodyB = fixB.GetBody()

        if (typeA === BODY_TYPES.PLAYER_FOOT && canJumpOnType(typeB, fixB)) {
          handleFootEnterContact(playerA)
        } else if (
          typeB === BODY_TYPES.PLAYER_FOOT &&
          canJumpOnType(typeA, fixA)
        ) {
          handleFootEnterContact(playerB)
        } else if (isPlayer(typeA) && typeB === BODY_TYPES.PLATFORM) {
          handlePlayerCollidedWithPlatform(bodyA, bodyB, contact)
        } else if (isPlayer(typeB) && typeA === BODY_TYPES.PLATFORM) {
          handlePlayerCollidedWithPlatform(bodyB, bodyA, contact)
        } else if (isPlayer(typeA) && typeB === BODY_TYPES.BUTTON) {
          handleEnterButton(userB.id)
        } else if (isPlayer(typeB) && typeA === BODY_TYPES.BUTTON) {
          handleEnterButton(userA.id)
        } else if (isPlayer(typeA) && typeB === BODY_TYPES.DOOR) {
          handleDoorEnterContact(playerA)
        } else if (isPlayer(typeB) && typeA === BODY_TYPES.DOOR) {
          handleDoorEnterContact(playerB)
        } else if (isPlayer(typeA) && typeB === BODY_TYPES.SPRING) {
          handleSpringEnterContact(playerA)
        } else if (isPlayer(typeB) && typeA === BODY_TYPES.SPRING) {
          handleSpringEnterContact(playerB)
        } else if (
          typeA === BODY_TYPES.PLAYER_WALL_R &&
          (canJumpOnType(typeB, fixB) || typeB === BODY_TYPES.DOOR_FRAME)
        ) {
          handlePlayerTouchRightWall(playerA, fixB, true)
        } else if (
          typeB === BODY_TYPES.PLAYER_WALL_R &&
          (canJumpOnType(typeA, fixA) || typeA === BODY_TYPES.DOOR_FRAME)
        ) {
          handlePlayerTouchRightWall(playerB, fixA, true)
        } else if (
          typeA === BODY_TYPES.PLAYER_WALL_L &&
          (canJumpOnType(typeB, fixB) || typeB === BODY_TYPES.DOOR_FRAME)
        ) {
          handlePlayerTouchLeftWall(playerA, fixB, true)
        } else if (
          typeB === BODY_TYPES.PLAYER_WALL_L &&
          (canJumpOnType(typeA, fixA) || typeA === BODY_TYPES.DOOR_FRAME)
        ) {
          handlePlayerTouchLeftWall(playerB, fixA, true)
        }
      }
    }

    listener.EndContact = (contact) => {
      const fixA = contact.GetFixtureA()
      const fixB = contact.GetFixtureB()
      const userA = fixA.GetUserData()
      const userB = fixB.GetUserData()
      if (userA && userB) {
        const typeA = userA.type
        const playerA = userA.player
        const typeB = userB.type
        const playerB = userB.player

        if (typeA === BODY_TYPES.PLAYER_FOOT && canJumpOnType(typeB, fixB)) {
          handleFootLeaveContact(playerA)
        } else if (
          typeB === BODY_TYPES.PLAYER_FOOT &&
          canJumpOnType(typeA, fixA)
        ) {
          handleFootLeaveContact(playerB)
        } else if (typeA === BODY_TYPES.PLAYER && typeB === BODY_TYPES.BUTTON) {
          handleLeaveButton(userB.id)
        } else if (typeB === BODY_TYPES.PLAYER && typeA === BODY_TYPES.BUTTON) {
          handleLeaveButton(userA.id)
        } else if (isPlayer(typeA) && typeB === BODY_TYPES.DOOR) {
          handleDoorLeaveContact(playerA)
        } else if (isPlayer(typeB) && typeA === BODY_TYPES.DOOR) {
          handleDoorLeaveContact(playerB)
        } else if (isPlayer(typeA) && typeB === BODY_TYPES.SPRING) {
          handleSpringLeaveContact(playerA)
        } else if (isPlayer(typeB) && typeA === BODY_TYPES.SPRING) {
          handleSpringLeaveContact(playerB)
        } else if (
          typeA === BODY_TYPES.PLAYER_WALL_R &&
          (canJumpOnType(typeB, fixB) || typeB === BODY_TYPES.DOOR_FRAME)
        ) {
          handlePlayerTouchRightWall(playerA, fixB, false)
        } else if (
          typeB === BODY_TYPES.PLAYER_WALL_R &&
          (canJumpOnType(typeA, fixA) || typeA === BODY_TYPES.DOOR_FRAME)
        ) {
          handlePlayerTouchRightWall(playerB, fixA, false)
        } else if (
          typeA === BODY_TYPES.PLAYER_WALL_L &&
          (canJumpOnType(typeB, fixB) || typeB === BODY_TYPES.DOOR_FRAME)
        ) {
          handlePlayerTouchLeftWall(playerA, fixB, false)
        } else if (
          typeB === BODY_TYPES.PLAYER_WALL_L &&
          (canJumpOnType(typeA, fixA) || typeA === BODY_TYPES.DOOR_FRAME)
        ) {
          handlePlayerTouchLeftWall(playerB, fixA, false)
        }
      }
    }

    const physicsWorld = b2World.Create({ x: 0, y: -22 })
    physicsWorld.SetContactListener(listener)
    setPhysicsWorld(physicsWorld)
  }, [])

  let passedTime = 0

  const settings = {
    velocityIterations: 8,
    positionIterations: 3
  }

  useFrame((_, delta) => {
    if (physicsWorld) {
      passedTime += delta
      if (passedTime >= fixedTimeStep) {
        physicsWorld.Step(fixedTimeStep, settings)
        if (debugDraw && physicsWorld) {
          // drawDebug(debugDraw, physicsWorld)
        }
        passedTime = 0
      }
    }
  })

  if (!physicsWorld) return <></>

  return (
    <PhysicsContext.Provider value={physicsWorld}>
      {children}
    </PhysicsContext.Provider>
  )
}

export const usePhysics = (): b2World => useContext(PhysicsContext)
