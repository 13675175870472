import React from 'react'

const PhysicsDebug: React.FC = () => {
  return (
    <>
      <canvas
        id="physics-canvas"
        width="500px"
        height="500px"
        style={{
          position: 'absolute',
          background: 'black',
          top: '0',
          left: 0,
          transition: 'all 300ms',
          display: 'none'
        }}
      />
    </>
  )
}

export default PhysicsDebug
