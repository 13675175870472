import React, { useEffect } from 'react'
import { useTexture } from '@react-three/drei'
import {
  BODY_TYPES,
  CATEGORY_PLATFORM,
  CATEGORY_PLAYER_1,
  CATEGORY_PLAYER_2,
  usePhysics
} from '../physics/Physics'
import { RepeatWrapping } from 'three'
import { b2BodyType, b2PolygonShape } from '../../../@box2d/core'
import { darkColor, lightColor } from '../../Game'

export interface BlockProps {
  segments: number
  x: number
  y: number
  screenIsOn: boolean
}

const Ground: React.FC<BlockProps> = ({ segments, x, y, screenIsOn }) => {
  const top = useTexture('./resources/sprites/world/block_top.png').clone()
  const left = useTexture('./resources/sprites/world/block_left.png')
  const right = useTexture('./resources/sprites/world/block_right.png')

  top.wrapS = RepeatWrapping
  top.repeat.set(segments, 1)

  const physics = usePhysics()

  useEffect(() => {
    const ground = physics.CreateBody({
      type: b2BodyType.b2_staticBody,
      position: { x, y: y - 0.07 }
    })
    ground.CreateFixture({
      shape: new b2PolygonShape().SetAsBox(segments / 2 + 0.9, 0.25),
      userData: {
        type: BODY_TYPES.PLATFORM
      },
      filter: {
        categoryBits: CATEGORY_PLATFORM,
        maskBits: CATEGORY_PLAYER_1 | CATEGORY_PLAYER_2
      }
    })
  }, [])

  return (
    <object3D>
      <sprite position={[x - segments / 2 - 0.5, y, 0]}>
        <spriteMaterial
          map={left}
          color={screenIsOn ? darkColor : lightColor}
        />
      </sprite>
      <sprite position={[x, y, 0]} scale={[segments, 1, 1]}>
        <spriteMaterial map={top} color={screenIsOn ? darkColor : lightColor} />
      </sprite>
      <sprite position={[x + segments / 2 + 0.5, y, 0]}>
        <spriteMaterial
          map={right}
          color={screenIsOn ? darkColor : lightColor}
        />
      </sprite>
    </object3D>
  )
}

export default Ground
